import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Category from "../components/category";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import clientConfig from "../../client-config";

import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityCategory(id: { eq: $id }) {
      id

      icon {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }

      title
    }

    projects: allSanitySampleProject(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          categories {
            title
            icon {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
              alt
            }
          }
        }
      }
    }
  }
`;

const CategoryTemplate = (props) => {
  const { data, errors } = props;
  const category = data && data.category;
  const projectNodes = data && data.projects;

  const imageURL = imageUrlFor(buildImageObj(category.icon))
    .width(1200)
    .height(630)
    .fit("crop")
    .auto("format")
    .url();

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {category && <SEO title={category.title || "Untitled"} image={imageURL} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {category && projectNodes && <Category {...category} projectNodes={projectNodes} />}
    </Layout>
  );
};

export default CategoryTemplate;
