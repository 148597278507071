import { format, formatDistance, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import Container from "./container";
import RoleList from "./role-list";

import Layout from "../containers/layout";
import Gridcontainer from "../components/gridcontainer";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";

import { responsiveTitle0 } from "../components/typography.module.css";

import * as styles from "./project.module.css";

function Category(props) {
  const { title, icon, projectNodes } = props;


  // return (
  //   <article>
  //         {/* {icon && (<img
  //           src={imageUrlFor(buildImageObj(icon))
  //             .width(400)
  //             // .height(Math.floor((9 / 16) * 1200))
  //             .fit("fill")
  //             .url()}
  //           alt={icon.alt}
  //         />)} */}

  //     <Container>
  //       <div className={styles.grid}>
  //         <div className={styles.mainContent}>
  //           <h1 className={styles.title}>{title}</h1>
  //         </div>
  //       </div>
  //     </Container>
  //   </article>
  // );

  return (
      <Gridcontainer>
        <h1 className={responsiveTitle0}>{title}</h1>
        {projectNodes && projectNodes.edges.length > 0 && <ProjectPreviewGrid nodes={projectNodes.edges.map(edge => edge.node)} />}
      </Gridcontainer>
  );
}

export default Category;
